/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy, Suspense } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route } from 'react-router-dom';

import GoogleTagManager from 'Component/GoogleTagManager';
import Meta from 'Component/Meta';
import ThirdPartyScriptHandler from 'Component/ThirdPartyScriptHandler';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    DemoNotice,
    Footer,
    Header,
    HomePage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import history from 'Util/History';

import {
    BEFORE_ITEMS_TYPE,
    FAVOURITES,
    RETAILER_APPLICATION,
    SEARCH_NO_QUERY,
    SWITCH_ITEMS_TYPE,
    UNIVERSITY
} from './Router.config';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    SearchPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    withStoreRegex
};

export const PasswordChangePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "password-change" */ 'Route/PasswordChangePage'));
export const WishlistShared = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "wishlist-shared" */ 'Route/WishlistSharedPage'));
export const CreateAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "create-account" */ 'Route/CreateAccount'));
export const LoginAccountPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "login-account" */ 'Route/LoginAccount'));
export const ForgotPasswordPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "forgot-password" */ 'Route/ForgotPassword'));
export const FavouritesPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "favourites" */ 'Route/FavouritesPage'));
export const RetailerApplication = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "retailer-application" */ 'Route/RetailerApplication'));
export const UniversityPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "university" */ 'Route/UniversityPage'));

/** @namespace Bellinger/Component/Router/Component */
export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        ...this[BEFORE_ITEMS_TYPE],
        {
            component: <GoogleTagManager />,
            position: 40
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: <Route
              path={ withStoreRegex('/search') }
              render={ (props) => <SearchPage { ...props } /> }
            />,
            position: 26,
            name: SEARCH_NO_QUERY
        },
        {
            component: <Route
              path={ withStoreRegex('/favorites') }
              render={ (props) => (
                  <FavouritesPage { ...props } />
              ) }
            />,
            position: 85,
            name: FAVOURITES
        },
        {
            component: <Route
              path={ withStoreRegex('/customer/account/createPassword') }
              render={ (props) => <RetailerApplication { ...props } /> }
            />,
            position: 59,
            name: RETAILER_APPLICATION
        },
        {
            component: <Route
              path={ withStoreRegex('/university') }
              exact
              render={ (props) => <UniversityPage { ...props } /> }
            />,
            position: 86,
            name: UNIVERSITY
        }
    ];

    render() {
        return (
            <>
                <Meta />
                <ThirdPartyScriptHandler />
                <Suspense fallback={ this.renderFallbackPage() }>
                    <ReactRouter history={ history }>
                        { this.renderRouterContent() }
                    </ReactRouter>
                </Suspense>
            </>
        );
    }
}

export default Router;
