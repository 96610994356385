import { POLARIZEDLENSE_UPDATE_DETAILS } from './PolarizedLense.action';

/** @namespace Bellinger/Store/PolarizedLense/Reducer/getInitialState */
export const getInitialState = () => ({
    polarizedLenseProduct: {}
});

/** @namespace Bellinger/Store/PolarizedLense/Reducer/PolarizedLenseReducer */
export const PolarizedLenseReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case POLARIZEDLENSE_UPDATE_DETAILS:
        const { payload } = action;

        return {
            polarizedLenseProduct: payload
        };

    default:
        return state;
    }
};

export default PolarizedLenseReducer;
