import { lazy } from 'react';

import {
    CategoryPage,
    CmsPage,
    NoMatch,
    ProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_CONTENT,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';

export {
    ProductPage,
    CategoryPage,
    NoMatch,
    CmsPage
};

export const ContentPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/ContentPage')
);

/** @namespace Bellinger/Route/UrlRewrites/Component */
export class UrlRewrites extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;
        const {
            id,
            history,
            location,
            match,
            productSKU,
            categoryIds,
            pageIds
        } = props;

        switch (type) {
        case TYPE_PRODUCT:
            return (
                <ProductPage
                  history={ history }
                  location={ location }
                  match={ match }
                  productSKU={ productSKU }
                  productID={ id }
                  key={ id }
                />
            );
        case TYPE_CMS_PAGE:
            return (
                <CmsPage
                  history={ history }
                  location={ location }
                  match={ match }
                  pageIds={ pageIds }
                />
            );
        case TYPE_CONTENT:
            return (
                <ContentPage
                  history={ history }
                  location={ location }
                  match={ match }
                  id={ id }
                />
            );
        case TYPE_CATEGORY:
            return (
                <CategoryPage
                  history={ history }
                  location={ location }
                  match={ match }
                  categoryIds={ categoryIds }
                />
            );
        case TYPE_NOTFOUND:
            return (
                <NoMatch
                  history={ history }
                  location={ location }
                  match={ match }
                />
            );
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;
