/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const addBlogConfigField = (args, callback) => [
    ...callback(...args),
    'mfblog_post_list_posts_per_page'
];

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: addBlogConfigField
        }
    }
};
