import { getVariantsIndexes } from 'SourceUtil/Product';

export * from 'SourceUtil/Product/Product';

/** @namespace Bellinger/Util/Product/getConfigurableParametersFromFilters */
export const getConfigurableParametersFromFilters = (product, selectedFilters) => {
    const { variants = [] } = product;
    const filterKeys = Object.keys(selectedFilters);

    if (filterKeys.length < 0) {
        return { indexes: [], parameters: {} };
    }

    const indexes = getVariantsIndexes(variants, selectedFilters);
    const [index] = indexes;

    if (!variants[index]) {
        return { indexes: [], parameters: {} };
    }
    const { attributes } = variants[index];

    const parameters = Object.entries(attributes)
        .reduce((parameters, [key, { attribute_value }]) => {
            if (filterKeys.includes(key)) {
                return { ...parameters, [key]: attribute_value };
            }

            return parameters;
        }, {});

    return { indexes, index, parameters };
};

/** @namespace Bellinger/Util/Product/getCurrentVariantIndexFromFilters */
export const getCurrentVariantIndexFromFilters = (product, selectedFilters) => {
    if (!Object.keys(selectedFilters).length) {
        return -1;
    }

    const { index } = getConfigurableParametersFromFilters(product, selectedFilters);
    return index >= 0 ? index : -1;
};

/** @namespace Bellinger/Util/Product/formatBelConfigAttriubte */
export const formatBelConfigAttriubte = (option) => {
    const { attribute_options } = option;
    const formattedAttributeOptions = {};

    Object.entries(attribute_options).forEach(([key, { value, label }]) => {
        const labels = label.split('-').filter((string) => (!string.includes('#') && string !== 'N/A'));
        const [color1, color2] = label.split('-').filter((string) => string.includes('#'));

        formattedAttributeOptions[key] = {
            label: labels.join('-'),
            value,
            swatch_data: {
                type: '1',
                value: `${color1}${color2 ? `-${color2}` : '' }`
            }
        };
    });

    const formattedOption = {
        ...option,
        attribute_options: formattedAttributeOptions,
        has_swatch: true
    };

    return formattedOption;
};
