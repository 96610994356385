import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ThirdPartyScriptHandler from './ThirdPartyScriptHandler.component';

/** @namespace Bellinger/Component/ThirdPartyScriptHandler/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cookieBotId: state.ConfigReducer.bel_cookie_bot_unique_id
});

/** @namespace Bellinger/Component/ThirdPartyScriptHandler/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Bellinger/Component/ThirdPartyScriptHandler/Container */
export class ThirdPartyScriptHandlerContainer extends PureComponent {
    static propTypes = {
        cookieBotId: PropTypes.string
    };

    static defaultProps = {
        cookieBotId: ''
    };

    containerProps = () => {
        const { cookieBotId } = this.props;
        return {
            cookieBotId
        };
    };

    render() {
        return (
            <ThirdPartyScriptHandler
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyScriptHandlerContainer);
