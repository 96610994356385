import StoreLocatorReducer from '../store/StoreLocator/StoreLocator.reducer';

export class StoreIndexPlugin {
    /**
     * Plugin to add storelocator reducer to getStaticReducers method.
     * @param args
     */
    aroundGetStaticReducers = (args, callback) => ({
        ...callback(...args),
        StoreLocatorReducer
    });
}

const { aroundGetStaticReducers } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: aroundGetStaticReducers
    }
};

export default config;
