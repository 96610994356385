import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerPasswordForgotStatus,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { setAuthorizationToken } from 'Util/Auth';
import { setGuestQuoteId } from 'Util/Cart';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS
};

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Bellinger/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    // Removed dispatch(showNotification('success', ...)) from signIn()
    // -> No success message notification required after login/signin
    async signIn(options = {}, dispatch) {
        try {
            dispatch(updateIsLoading(true));

            const mutation = MyAccountQuery.getSignInMutation(options);

            const result = await fetchMutation(mutation);
            const token = result?.generateCustomerTokenByCustomerNo?.token || result?.generateCustomerToken?.token;
            setAuthorizationToken(token);
            await this.requestCustomerData(dispatch);
            dispatch(updateCustomerSignInStatus(true));
        } catch (error) {
            dispatch(updateIsLoading(false));
            dispatch(hideActiveOverlay());
            return dispatch(showNotification('error', getErrorMessage(error)));
        }

        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());

        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        );

        const cartDispatcher = (await CartDispatcher).default;

        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        // This has been removed as it's B2B so customer cannot add products to guest cart, therefore there is no need to merge
        // if (guestCartToken && guestCartToken !== customerCartToken) {
        // merge guest cart id and customer cart id using magento capabilities
        // await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        // }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        return true;
    }

    belForgotPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getForgotPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Bellinger/Store/MyAccount/Dispatcher/MyAccountDispatcher/belForgotPassword/fetchMutation/then */
            ({ belForgotPassword: { is_success } }) => {
                if (!is_success) {
                    return { is_success };
                }

                dispatch(updateCustomerPasswordForgotStatus());
                return { is_success };
            },
            /** @namespace Bellinger/Store/MyAccount/Dispatcher/MyAccountDispatcher/belForgotPassword/fetchMutation/then/dispatch/catch */
            (error) => dispatch(showNotification('error', getErrorMessage(error)))
        );
    }
}

export default new MyAccountDispatcher();
