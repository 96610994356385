/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace Bellinger/Component/Loader/Component */
export class Loader extends SourceLoader {
    static propTypes = {
        style: PropTypes.object
    };

    static defaultProps = {
        style: {}
    };

    renderMain() {
        return (
            <div
              block="LoaderNew"
              elem="Main"
            >
                <div />
                <div />
                <div />
                <div />
            </div>
        );
    }

    render() {
        const { isLoading, style } = this.props;
        if (!isLoading) {
            return null;
        }

        return (
            <div
              block="Loader"
              style={ style }
            >
                <div
                  block="Loader"
                  elem="Scale"
                >
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default Loader;
