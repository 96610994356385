/* eslint-disable no-magic-numbers */
import {
    WishlistQuery as SourceWishlistQuery
} from 'SourceQuery/Wishlist.query';
import { Field } from 'Util/Query';

/** @namespace Bellinger/Query/Wishlist/Query */
export class WishlistQuery extends SourceWishlistQuery {
    getWishlistQuery(sharingCode, options) {
        const field = new Field('wishlist')
            .addFieldList(this._getWishlistFields(options));

        if (sharingCode) {
            field.addArgument('sharing_code', 'ID', sharingCode);
        }

        return field;
    }

    _getWishlistFields(options) {
        return [
            'id',
            'updated_at',
            'items_count',
            this._getBelItemsField(options)
        ];
    }

    _getBelItemsField(options) {
        const { currentPage = 1, pageSize = 15 } = options || {};

        return new Field('bel_items')
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addField(this._getItemsField());
    }

    _getItemsFields() {
        return [
            'added_at',
            'id',
            this._getProductField()
        ];
    }

    _getPageInfo() {
        return new Field('page_info')
            .addFieldList([
                'current_page',
                'page_size',
                'total_pages'
            ]);
    }
}

export default new WishlistQuery();
