export const UPDATE_IS_LOADING = 'UPDATE_IS_LOADING';
export const UPDATE_STORES = 'UPDATE_STORES';
export const UPDATE_SL_CONFIG = 'UPDATE_SL_CONFIG';
export const UPDATE_SL_CONFIG_LOADING = 'UPDATE_SL_CONFIG_LOADING';

/** @namespace Sdm/Storelocator/Store/StoreLocator/Action/updateStores */
export const updateStores = (items, totalCount, currentPage) => ({
    type: UPDATE_STORES,
    stores: items,
    totalCount,
    currentPage
});

/** @namespace Sdm/Storelocator/Store/StoreLocator/Action/updateIsLoading */
export const updateIsLoading = (isLoading) => ({
    type: UPDATE_IS_LOADING,
    isLoading
});

/** @namespace Sdm/Storelocator/Store/StoreLocator/Action/updateSlConfig */
export const updateSlConfig = (config) => ({
    type: UPDATE_SL_CONFIG,
    config
});

/** @namespace Sdm/Storelocator/Store/StoreLocator/Action/updateSlConfigLoading */
export const updateSlConfigLoading = (isConfigLoading) => ({
    type: UPDATE_SL_CONFIG_LOADING,
    isConfigLoading
});
