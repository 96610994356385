import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import CustomerViewReducer from 'Store/CustomerView/CustomerView.reducer';
import MyAccountReducer from 'Store/MyAccount/MyAccount.reducer';
import OrderReducer from 'Store/Order/Order.reducer';
import PolarizedLenseReducer from 'Store/PolarizedLense/PolarizedLense.reducer';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';

/** @namespace Bellinger/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    CustomerViewReducer,
    SearchBarReducer,
    OrderReducer,
    MyAccountReducer,
    PolarizedLenseReducer
});

export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
