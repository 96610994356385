import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';
import { VALIDATION_RULES } from 'Util/Validator/Config';

import WishlistQuery from './Wishlist.query';

/** @namespace Bellinger/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerField() {
        return new Field('customer')
            .addFieldList(this._getCustomerFields())
            .addField(WishlistQuery.getWishlistQuery());
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'bel_company_name',
            'bellinger_id',
            'id',
            'is_subscribed',
            'bel_contact_email',
            this._getAddressesField(),
            'bellinger_box_status',
            'bellinger_brand',
            'bellinger_quantity',
            'bellinger_less',
            'bellinger_turnover_thisyear',
            'bellinger_turnover_lastyear',
            'bellinger_currency',
            'bellinger_partnertype',
            'bellinger_outlet'
        ];
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            'company',
            this._getRegionField()
        ];
    }

    getCustomerRegistrationInfo(options) {
        const { customerId, token } = options || {};

        return new Field('belGetCustomerRegistrationInfo')
            .addArgument('customerId', 'Int', customerId)
            .addArgument('token', 'String', token)
            .addFieldList(this._getCustomerRegistrationInfoFields());
    }

    _getCustomerRegistrationInfoFields() {
        return [
            'agent_name',
            'company_name',
            'company_number',
            'email'
        ];
    }

    getSignInMutation(options) {
        const { customerNumber, password } = options;

        if (customerNumber.match(VALIDATION_RULES.email)) {
            return new Field('generateCustomerToken')
                .addArgument('email', 'String!', customerNumber)
                .addArgument('password', 'String!', password)
                .addField('token');
        }

        return new Field('generateCustomerTokenByCustomerNo')
            .addArgument('customerNumber', 'String!', customerNumber)
            .addArgument('password', 'String!', password)
            .addField('token');
    }

    getCustomerUrlByCardCode(customerNumber) {
        return new Field('belGetCustomerUrlByCardCode')
            .addArgument('customerNumber', 'String!', customerNumber)
            .addField('customer_base_url');
    }

    getForgotPasswordMutation(options) {
        const { contactEmail, customerNumber } = options;

        return new Field('belForgotPassword')
            .addArgument('belContactEmail', 'String!', contactEmail)
            .addArgument('cardCode', 'String!', customerNumber)
            .addField('status')
            .addField('is_success');
    }

    getBelValidateContactEmail(option) {
        return new Field('belValidateContactEmail')
            .addArgument('belContactEmail', 'String!', option);
    }
}

export default new MyAccountQuery();
