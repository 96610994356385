import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Bellinger/Component/ThirdPartyScriptHandler/Component */
export class ThirdPartyScriptHandler extends PureComponent {
    static propTypes = {
        cookieBotId: PropTypes.string.isRequired
    };

    componentDidMount() {
        this.injectScripts();
    }

    injectScripts() {
        this.handleCookieBotScript();
    }

    handleCookieBotScript() {
        const { cookieBotId } = this.props;

        if (!cookieBotId) {
            return;
        }

        const oldScript = document.head.querySelector('#Cookiebot');

        if (oldScript) {
            oldScript.remove();
        }

        const script = document.createElement('script');
        script.setAttribute('id', 'Cookiebot');
        script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
        script.setAttribute('data-cbid', cookieBotId);
        script.setAttribute('type', 'text/javascript');
        script.async = true;

        const comment = document.createComment('Cookiebot Script');

        document.head.insertBefore(script, document.head.childNodes[0]);
        document.head.insertBefore(comment, document.head.childNodes[0]);
    }

    render() {
        return null;
    }
}

export default ThirdPartyScriptHandler;
