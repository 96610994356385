export * from 'SourceStore/Wishlist/Wishlist.action.js';
export const UPDATE_ALL_PRODUCTS_IN_WISHLIST = 'UPDATE_ALL_PRODUCTS_IN_WISHLIST';

/**
 * Update all products in wishlist
 * @param  {Array} products Products that should be updated in wishlist
 * @return {void}
 * @namespace Bellinger/Store/Wishlist/Action/updateAllProductsInWishlist */
export const updateAllProductsInWishlist = (products, wishlistId) => ({
    type: UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    products,
    wishlistId
});
