import {
    getInitialState as sourceInitialState
} from 'SourceStore/MyAccount/MyAccount.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_IS_LOCKED,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS
} from './MyAccount.action';
import { CUSTOMER } from './MyAccount.dispatcher';

/** @namespace Bellinger/Store/MyAccount/Reducer/getGroupRestricted */
// eslint-disable-next-line no-magic-numbers
export const getGroupRestricted = (groupId) => (groupId ? (groupId === 23) || (groupId === 37) : false);

/** @namespace Bellinger/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => {
    const customer = BrowserDatabase.getItem(CUSTOMER) || {};
    const groupId = customer.group_id || 0;

    return {
        ...sourceInitialState(),
        customer,
        isGroupRestricted: getGroupRestricted(groupId)
    };
};

/** @namespace Bellinger/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const {
        status, customer, message, email
    } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_SIGN_IN_STATUS:
        return {
            ...state,
            isSignedIn: status
        };

    case UPDATE_CUSTOMER_PASSWORD_RESET_STATUS:
        return {
            ...state,
            passwordResetStatus: status,
            passwordResetMessage: message
        };

    case UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS:
        return {
            ...state,
            isPasswordForgotSend: !state.isPasswordForgotSend
        };

    case UPDATE_CUSTOMER_DETAILS:
        return {
            ...state,
            customer,
            isGroupRestricted: getGroupRestricted(customer.group_id)
        };
    case UPDATE_CUSTOMER_PASSWORD_FORGOT_EMAIL:
        return {
            ...state,
            email
        };
    case UPDATE_CUSTOMER_IS_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case UPDATE_CUSTOMER_IS_LOCKED:
        const { isLocked } = action;

        return {
            ...state,
            isLocked
        };

    default:
        return state;
    }
};

export default MyAccountReducer;
