import { UPDATE_CURRENT_CATEGORY } from './BlogCategory.action';

/** @namespace Blog/Store/BlogCategory/Reducer/getInitialState */
export const getInitialState = () => ({
    currentCategory: {}
});

/** @namespace Blog/Store/BlogCategory/Reducer/CategoryReducer */
export const CategoryReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_CURRENT_CATEGORY:
        const { category } = action;

        return {
            ...state,
            currentCategory: category
        };

    default:
        return state;
    }
};

export default CategoryReducer;
