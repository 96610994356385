/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
import {
    getInitialState as sourceInitialState
} from 'SourceStore/Order/Order.reducer';
import { getIndexedProducts } from 'Util/Product';

import {
    APPEND_PAGE,
    SET_CLOSED_LOADING_STATUS,
    SET_CLOSED_ORDER_LIST,
    SET_CREDIT_LIST,
    SET_CREDIT_LOADING,
    SET_INVOICE_LIST,
    SET_INVOICE_LOADING,
    SET_ORDER_LIST,
    SET_ORDER_LOADING_STATUS,
    SET_PRODUCT_LIST,
    SET_PRODUCT_LOADING_STATUS,
    UPDATE_PAGE_LOAD_STATUS
} from './Order.action';

export const initialState = {
    ...sourceInitialState(),
    products: {
        pages: {},
        totalItems: 0,
        totalPages: 0
    },
    invoices: [],
    invoicesLoading: true,
    invoicePageInfo: {
        totalPages: 0
    },
    credits: [],
    creditsLoading: true,
    creditsPageInfo: {
        totalPages: 0
    },
    productLoadingStatus: true,
    isPageLoading: false,
    closedLoading: false,
    closedOrderList: {},
    closedCount: 0
};

/** @namespace Bellinger/Store/Order/Reducer/OrderReducer */
export const OrderReducer = (state = initialState, action) => {
    const {
        orderList,
        type,
        status,
        items = {},
        options = {}
    } = action;

    const { items: products = [], total_count: totalItems = 0 } = items;
    const { args = {}, currentPage, pageSize } = options;
    let pageNumber = currentPage;
    let productsPerPage = pageSize;

    if (Object.entries(args).length) {
        pageNumber = args.currentPage;
        productsPerPage = args.pageSize;
    }

    switch (type) {
    case APPEND_PAGE:
        return {
            ...state,
            isPageLoading: false,
            products: {
                ...state.products,
                pages: {
                    [pageNumber]: getIndexedProducts(products)
                }
            }
        };
    case UPDATE_PAGE_LOAD_STATUS:
        return {
            ...state,
            isPageLoading: true
        };
    case SET_PRODUCT_LIST:
        return {
            ...state,
            productLoadingStatus: false,
            products: {
                pages: {
                    [pageNumber]: getIndexedProducts(products)
                },
                totalPages: Math.ceil(totalItems / productsPerPage),
                totalItems
            }
        };
    case SET_PRODUCT_LOADING_STATUS:
        return {
            ...state,
            productLoadingStatus: true
        };
    case SET_ORDER_LIST:
        const { items: orderListItems = [], total_pages, total_count } = orderList;

        return {
            ...state,
            isLoading: status,
            orderList: { items: orderListItems, pageInfo: { total_pages } },
            totalCount: total_count
        };
    case SET_CLOSED_ORDER_LIST:
        const { items: closedOrders = [], total_pages: closedPages, total_count: closedCount } = orderList;

        return {
            ...state,
            closedLoading: status,
            closedOrderList: { items: closedOrders, pageInfo: { total_pages: closedPages } },
            closedCount
        };
    case SET_CLOSED_LOADING_STATUS:
        return {
            ...state,
            closedLoading: status
        };
    case SET_ORDER_LOADING_STATUS:
        return {
            ...state,
            isLoading: status
        };
    case SET_INVOICE_LIST:
        const { invoices: { items: invoices, total_count: totalCount, total_pages: totalPages } } = action;
        return {
            ...state,
            invoices,
            invoicesLoading: false,
            invoicePageInfo: {
                totalCount,
                totalPages
            }
        };
    case SET_INVOICE_LOADING:
        const { invoicesLoading } = action;

        return {
            ...state,
            invoicesLoading
        };
    case SET_CREDIT_LIST:
        const { credits: { items: credits, total_count: creditsCount, total_pages: creditsPages } } = action;
        return {
            ...state,
            credits,
            creditsLoading: false,
            creditsPageInfo: {
                totalCount: creditsCount,
                totalPages: creditsPages
            }
        };
    case SET_CREDIT_LOADING:
        const { creditsLoading } = action;

        return {
            ...state,
            creditsLoading
        };
    default:
        return state;
    }
};

export default OrderReducer;
