import {
    clearWishlist,
    removeItemFromWishlist,
    updateItemOptions
} from 'SourceStore/Wishlist/Wishlist.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedParameteredProducts } from 'Util/Product';

import {
    CLEAR_WISHLIST,
    REMOVE_ITEM_FROM_WISHLIST,
    UPDATE_ALL_PRODUCTS_IN_WISHLIST,
    UPDATE_IS_LOADING_IN_WISHLIST,
    UPDATE_ITEM_OPTIONS
} from './Wishlist.action';

export const PRODUCTS_IN_WISHLIST = 'wishlist_products';
export const WISHLIST_ID = 'id';

/** @namespace Bellinger/Store/Wishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInWishlist: BrowserDatabase.getItem(PRODUCTS_IN_WISHLIST) || {},
    id: BrowserDatabase.getItem(WISHLIST_ID),
    isLoading: true
});
/** @namespace Bellinger/Store/Wishlist/Reducer/updateAllProductsInWishlist */
export const updateAllProductsInWishlist = (action) => {
    const { products: initialProducts, wishlistId } = action;

    const products = getIndexedParameteredProducts(initialProducts);

    BrowserDatabase.setItem(
        products,
        PRODUCTS_IN_WISHLIST,
        WISHLIST_ID
    );

    return { productsInWishlist: products, id: wishlistId, isLoading: false };
};
/** @namespace Bellinger/Store/Wishlist/Reducer/WishlistReducer */
export const WishlistReducer = (
    state = getInitialState(),
    action
) => {
    const { type, options } = action;

    switch (type) {
    case REMOVE_ITEM_FROM_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...removeItemFromWishlist(action, state)
        };

    case CLEAR_WISHLIST:
        return {
            ...state,
            ...clearWishlist()
        };

    case UPDATE_ALL_PRODUCTS_IN_WISHLIST:
        return {
            ...state,
            isLoading: false,
            ...updateAllProductsInWishlist(action)
        };

    case UPDATE_ITEM_OPTIONS:
        return {
            ...state,
            ...updateItemOptions(options, state)
        };

    case UPDATE_IS_LOADING_IN_WISHLIST:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default WishlistReducer;
