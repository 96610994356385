/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

export const STORE_LOCATOR = 'STORE_LOCATOR';

export const StoreLocator = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "store-locator" */ '../route/StoreLocator')
);
export const NotificationList = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList')
);

export class RouterPlugin {
    /**
     * Adds storelocator url handles to the router SWITCH_ITEMS_TYPE list
     * @param originalMember
     * @returns {*[]}
     */
    switchItems = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/store-locator') } component={ StoreLocator } />,
            position: 120,
            name: STORE_LOCATOR
        }
    ];
}

const { switchItems } = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};

export default config;
