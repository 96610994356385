export * from 'SourceStore/Order/Order.action.js';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_PRODUCT_LOADING_STATUS = 'SET_PRODUCT_LOADING_STATUS';
export const SET_INVOICE_LOADING = 'SET_INVOICE_LOADING';
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST';
export const SET_CREDIT_LIST = 'SET_CREDIT_LIST';
export const SET_CREDIT_LOADING = 'SET_CREDIT_LOADING';
export const APPEND_PAGE = 'APPEND_PAGE';
export const UPDATE_PAGE_LOAD_STATUS = 'UPDATE_PAGE_LOAD_STATUS';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_CLOSED_ORDER_LIST = 'SET_CLOSED_ORDER_LIST';
export const SET_CLOSED_LOADING_STATUS = 'SET_CLOSED_LOADING_STATUS';
export const SET_ORDER_LOADING_STATUS = 'SET_ORDER_LOADING_STATUS';

/** @namespace Bellinger/Store/Order/Action/setProductList */
export const setProductList = (items, options) => ({
    type: SET_PRODUCT_LIST,
    items,
    options
});

/** @namespace Bellinger/Store/Order/Action/setProductLoadingStatus */
export const setProductLoadingStatus = () => ({
    type: SET_PRODUCT_LOADING_STATUS
});

/** @namespace Bellinger/Store/Order/Action/appendPage */
export const appendPage = (items, options) => ({
    type: APPEND_PAGE,
    items,
    options
});

/** @namespace Bellinger/Store/Order/Action/updatePageLoadingStatus */
export const updatePageLoadingStatus = () => ({
    type: UPDATE_PAGE_LOAD_STATUS
});

/** @namespace Bellinger/Store/Order/Action/setInvoicesLoading */
export const setInvoicesLoading = (invoicesLoading) => ({
    type: SET_INVOICE_LOADING,
    invoicesLoading
});

/** @namespace Bellinger/Store/Order/Action/setInvoiceList */
export const setInvoiceList = (invoices) => ({
    type: SET_INVOICE_LIST,
    invoices
});

/** @namespace Bellinger/Store/Order/Action/setCreditsLoading */
export const setCreditsLoading = (creditsLoading) => ({
    type: SET_CREDIT_LOADING,
    creditsLoading
});

/** @namespace Bellinger/Store/Order/Action/setCreditList */
export const setCreditList = (credits) => ({
    type: SET_CREDIT_LIST,
    credits
});

/** @namespace Bellinger/Store/Order/Action/setOrderList */
export const setOrderList = (orderList, status) => ({
    type: SET_ORDER_LIST,
    orderList,
    status
});

/** @namespace Bellinger/Store/Order/Action/setOrderLoadingStatus */
export const setOrderLoadingStatus = (status) => ({
    type: SET_ORDER_LOADING_STATUS,
    status
});

/** @namespace Bellinger/Store/Order/Action/setClosedOrderList */
export const setClosedOrderList = (orderList, status) => ({
    type: SET_CLOSED_ORDER_LIST,
    orderList,
    status
});

/** @namespace Bellinger/Store/Order/Action/setClosedLoadingStatus */
export const setClosedLoadingStatus = (status) => ({
    type: SET_CLOSED_LOADING_STATUS,
    status
});
