import { connect } from 'react-redux';

import {
    CartDispatcher,
    ConfigDispatcher,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    MyAccountDispatcher,
    RouterContainer as SourceRouterContainer,
    WishlistDispatcher
} from 'SourceComponent/Router/Router.container';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';

export {
    mapStateToProps,
    CartDispatcher,
    ConfigDispatcher,
    WishlistDispatcher,
    MyAccountDispatcher
};
/** @namespace Bellinger/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    init: () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch)
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    }
});

/** @namespace Bellinger/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    async handleResize() {
        const { updateConfigDevice } = this.props;

        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                safari: isMobile.safari(model),
                windows: isMobile.windows(model),
                customWindow: isMobile.customWindow(),
                customNarrowMobile: isMobile.customNarrowMobile()
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                safari: isMobile.safari(),
                windows: isMobile.windows(),
                customWindow: isMobile.customWindow(),
                customNarrowMobile: isMobile.customNarrowMobile()
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
