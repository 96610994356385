import { CUSTOMER_VIEW } from 'Component/TopMenu/TopMenu.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { UPDATE_CUSTOMER_VIEW } from './CustomerView.action';

/** @namespace Bellinger/Store/CustomerView/Reducer/getInitialState */
export const getInitialState = () => ({
    isCustomerViewActive: BrowserDatabase.getItem(CUSTOMER_VIEW)
});

/** @namespace Bellinger/Store/CustomerView/Reducer/CustomerViewReducer */
export const CustomerViewReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_CUSTOMER_VIEW:
        const { payload } = action;

        return {
            ...state,
            isCustomerViewActive: payload
        };

    default:
        return state;
    }
};

export default CustomerViewReducer;
