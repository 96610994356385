import {
    getInitialState as sourceGetInitialState
} from 'SourceStore/Checkout/Checkout.reducer';

import {
    UPDATE_CASES_INCLUDED,
    UPDATE_COMMENT,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';
/** @namespace Bellinger/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    comment: '',
    areCasesIncluded: false
});

/** @namespace Bellinger/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable
        };

    case UPDATE_COMMENT:
        const { comment } = action;

        return {
            ...state,
            comment
        };

    case UPDATE_CASES_INCLUDED:
        const { areCasesIncluded } = action;

        return {
            ...state,
            areCasesIncluded
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
