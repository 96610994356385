import {
    getInitialState
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_INFO
} from 'Store/ProductListInfo/ProductListInfo.action';
import { colourNameToHex } from 'Util/Colors';

export {
    getInitialState
};

export const DATE_TO_SEASONS = {
    '01': 'WINTER',
    '04': 'SPRING',
    '08': 'SUMMER',
    '09': 'AUTUMN'
};

/** @namespace Bellinger/Store/ProductListInfo/Reducer/getSeason */
export const getSeason = (dateTime) => {
    const date = dateTime.split('T')[0];
    const dateArray = date.split('-');
    const month = dateArray[1];
    const year = dateArray[0];

    const season = DATE_TO_SEASONS[month];
    return {
        season,
        label: `${season} ${year}`
    };
};

/** @namespace Bellinger/Store/ProductListInfo/Reducer/createCollectionsFilter */
export const createCollectionsFilter = (co, item) => {
    const {
        request_var: attribute_code,
        filter_items,
        is_boolean,
        has_swatch,
        position
    } = item;

    const { attribute_values, attribute_options } = filter_items
        .filter((item) => (item.label !== 'N/A' && item.label !== '-1'))
        .sort(
            // eslint-disable-next-line no-nested-ternary
            (a, b) => (a.label > b.label ? 1 : (a.label === b.lable ? 0 : -1))
        ).reduce((attribute, option) => {
            const { value_string, label } = option;
            const season = getSeason(label);
            // eslint-disable-next-line no-param-reassign
            option.label = season.label;

            const { attribute_values, attribute_options } = attribute;

            attribute_values.push(value_string);

            return {
                ...attribute,
                attribute_options: {
                    ...attribute_options,
                    [value_string]: option
                }
            };
        }, { attribute_values: [], attribute_options: {} });

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label: 'Collection',
            attribute_position: position,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
            is_boolean,
            has_swatch
        }
    };
};

/** @namespace Bellinger/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters) => filters.reduce((co, item) => {
    const {
        request_var: attribute_code,
        name: attribute_label,
        position: attribute_position,
        filter_items,
        is_boolean,
        has_swatch
    } = item;

    if (attribute_code === 'bellinger_launch_date') {
        return createCollectionsFilter(co, item, filters);
    }

    const { attribute_values, attribute_options } = filter_items
        .filter((item) => (item.label !== 'N/A' && item.label !== '-1'))
        .sort(
            // eslint-disable-next-line no-nested-ternary
            (a, b) => (a.label > b.label ? 1 : (a.label === b.lable ? 0 : -1))
        ).reduce((attribute, option) => {
            const { value_string } = option;
            const { attribute_values, attribute_options } = attribute;

            attribute_values.push(value_string);
            if (!option.swatch_data) {
                // eslint-disable-next-line no-param-reassign
                option.swatch_data = colourNameToHex(option.label);
            }

            return {
                ...attribute,
                attribute_options: {
                    ...attribute_options,
                    [value_string]: option
                }
            };
        }, { attribute_values: [], attribute_options: {} });

    if (attribute_code === 'bellinger_front_material_combined') {
        return {
            ...co,
            [attribute_code]: {
                attribute_code,
                attribute_label: 'Material',
                attribute_position: 50,
                attribute_values,
                attribute_type: 'select',
                attribute_options,
                is_boolean,
                has_swatch
            }
        };
    }

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label,
            attribute_position,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
            is_boolean,
            has_swatch
        }
    };
}, {});

// This hasn't been changed, it's required so our customer reduceFilters is used
/** @namespace Bellinger/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO: {
        const {
            selectedFilter,
            products: {
                filters: availableFilters = [],
                min_price,
                max_price,
                sort_fields: sortFields
            } = {}
        } = action;

        return {
            ...state,
            filters: reduceFilters(availableFilters),
            sortFields,
            minPrice: Math.floor(min_price),
            maxPrice: Math.ceil(max_price),
            isLoading: false,
            selectedFilter
        };
    }

    case UPDATE_INFO_LOAD_STATUS: {
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    }

    default:
        return state;
    }
};

export default ProductListReducer;
