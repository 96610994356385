import PropTypes from 'prop-types';

export * from 'SourceType/Account.type';

export const RegionType = PropTypes.oneOfType([
    PropTypes.shape({
        region_code: PropTypes.string,
        region: PropTypes.string,
        region_id: PropTypes.number
    })
]);

export const Addresstype = PropTypes.shape({
    city: PropTypes.string,
    country_id: PropTypes.string,
    customer_id: PropTypes.number,
    default_billing: PropTypes.bool,
    default_shipping: PropTypes.bool,
    firstname: PropTypes.string,
    id: PropTypes.number,
    lastname: PropTypes.string,
    middlename: PropTypes.string,
    postcode: PropTypes.string,
    prefix: PropTypes.string,
    RegionType,
    street: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    suffix: PropTypes.string,
    telephone: PropTypes.string,
    company: PropTypes.string
});

// Unused types that must be overided in Router.component.js
// When the Router component is extended [SWITCH_ITEMS_TYPE] cannot be overriden
// TODO: try to override the component not extend it
export const MY_CLAIMS = 'my-claims';
export const ACCOUNTING = 'accounting';
export const PENDING = 'pending';
export const REORDER = 're-order';
export const NEW_CLAIM = 'new-claim';
export const PREVIOUS_CLAIMS = 'previous-claims';
export const INVOICES = 'invoices';
export const CREDIT_NOTES = 'credit-notes';
export const CLOSED = 'closed';
