import {
    UPDATE_IS_LOADING, UPDATE_SL_CONFIG, UPDATE_SL_CONFIG_LOADING, UPDATE_STORES
} from './StoreLocator.action';

export const initialState = {
    stores: [],
    totalCount: 0,
    isLoading: true,
    storeLocatorConfig: {},
    isSlConfigLoading: true
};

/** @namespace Sdm/Storelocator/Store/StoreLocator/Reducer/StoreLocatorReducer */
export const StoreLocatorReducer = (state = initialState, action) => {
    switch (action.type) {
    case UPDATE_IS_LOADING:
        const { isLoading } = action;
        return {
            ...state,
            isLoading
        };
    case UPDATE_STORES:
        const {
            stores,
            totalCount,
            currentPage
        } = action;

        const updatedStores = currentPage > 1 ? [
            ...state.stores,
            ...stores
        ] : stores;

        return {
            ...state,
            stores: updatedStores,
            totalCount,
            isLoading: false
        };
    case UPDATE_SL_CONFIG_LOADING:
        const { isConfigLoading } = action;

        return {
            ...state,
            isSlConfigLoading: isConfigLoading
        };
    case UPDATE_SL_CONFIG:
        const { config } = action;

        return {
            ...state,
            storeLocatorConfig: config,
            isSlConfigLoading: false
        };
    default:
        return state;
    }
};

export default StoreLocatorReducer;
