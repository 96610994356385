export * from 'SourceStore/Checkout/Checkout.action';

export const UPDATE_CASES_INCLUDED = 'UPDATE_CASES_INCLUDED';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';

/** @namespace Bellinger/Store/Checkout/Action/updateCasesIncluded */
export const updateCasesIncluded = (areCasesIncluded) => ({
    type: UPDATE_CASES_INCLUDED,
    areCasesIncluded
});

/** @namespace Bellinger/Store/Checkout/Action/updateComment */
export const updateComment = (comment) => ({
    type: UPDATE_COMMENT,
    comment
});
